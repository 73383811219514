/* Patient Questionnaire */
.question-wrapper {
  height: 100px;
  margin: 10px 0px 8px 0px;
  padding: 20px;
}

.prompt {
  font-size: 32px;
  text-align: center;
  height: 55px;
}

.subtext {
  text-align: center;
  font-style: italic;
}

#answer-sets {
  margin: auto;
  max-width: 1024px;
}

.answer-set {
  display: flex;
  justify-content: space-around;
}

.free-form {
  margin: auto;
  width: 65%;
}

.check-response {
  position: fixed;
  bottom: 68px;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 15px;
}

.check-response > p {
  text-align: center;
}

.response-box {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 15px;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.blink {
  color: red;
  font-size: 24px;
  text-decoration: blink;
  animation-name: blinker;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

@media only screen and (max-width: 600px) {
  .question-wrapper {
    margin: 0px 0px 10px 0px;
  }
  .response-box {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 8px;
  }
  .free-form {
    width: 100%;
  }
  .check-response {
    bottom: 54px;
  }
}

@media all and (min-width: 0) and (max-width: 480px) {
  .History_QuestionText__D1uSg {
    font-size: 1.5rem !important;
    font-weight: 700;
  }
}

.headerWithSound {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.headerWithSound h1,
.headerWithSound h2,
.headerWithSound h3,
.headerWithSound h4 {
  flex-grow: 5;
}
.headerWithSound .modal-card-title {
  flex-grow: 5;
}
.headerSound {
  margin-inline-start: 1rem;
  flex-grow: 0;
  border-radius: 50%;
  /* background-color: rgba(23, 32, 122, 0.1); */
  padding: 10px;
}
.headerSound.disabled {
  color: #472320;
  text-align: center;
  width: 44px;
}

.custom-mx-neg-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.RTL {
  direction: rtl;
}
.modal-card-title {
  text-align: start;
}