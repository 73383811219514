.customized-modal > .modal-dialog > .modal-content {
  background: none !important;
  border: none !important;
}
.react-carousel-dots-holder {
  /* margin: 10% auto 0; */
  width: 45px !important; 
  justify-content: space-evenly;
}

.dot-holder {
  height: 8px !important;
  width: 8px !important;
}

.react-carousel-dots-dot {
  border: none !important;
  background-color: #a9a9a9 !important;
  bottom: 0;
}

.react-carousel-dots-dot.active {
  background-color: black !important;
}

.customized-modal {
  background-color: none !important;
  border: none !important;
}

.card.customized-modal-card {
  width: 90%;
  height: calc(100vh - 88px);
  border-radius: 0.4rem;
  padding: 0;
  margin: 0 auto;
}

.customized-modal-card-body {
  padding: 0.75em;
  width: 90%; 
  margin: auto;
  overflow-y: scroll;
}

.customized-modal-card-body > .modal-card-title {
  color: #17207a;
  text-align: left !important;
}

.modal-card-hr {
  width: 15%;
  border: 1px solid #17207a;
  margin: 0px 0px 0px;
}
.RTL .modal-card-hr {
  float: right;
}

.customized-modal-card-body > .modal-card-text {
  width: 92%; 
  margin: auto; 
  padding-bottom: .75rem; 
  font-size: .90rem; 
  font-weight: 400; 
  color: #a9a9a9; 
  text-align: justify; 
  text-justify: inter-word; 
  padding-top: .75rem;
}

.customized-modal-card-dot {
  margin-top: .75em;
  bottom: 0;
  position: relative;
  display: flex;
  justify-content: space-evenly; 
  align-items: center; 
}

.body-text {
  max-height: 3rem;
  text-align: left;
  /* text-justify: inter-word; 
  display: inline;
  vertical-align: middle; 
  margin-left: 12px; */
}

@media all and (min-width: 960px) and (max-width: 1024px) {
  /* put your css styles in here */
}

@media all and (min-width: 801px) and (max-width: 959px) {
  /* put your css styles in here */
}
