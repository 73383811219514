@import '../styles/common';

.InnerContainer {
  margin-bottom: 100px;
  max-width: 680px;
  margin: auto; 
}

.Footer {
  position: fixed;
  height: 75px;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space;
  background: rgba(256, 256, 256, 0.5);
  border-top: 2px #333333;
  box-shadow: 5px 10px 18px #888888;
  flex-direction: column-reverse;
}

.FullSize {
  max-width: 100%;
}

.Page {
  margin-bottom: 100px;
}

.QuestionText {
  font-size: 24px;
  font-weight: 500;
  color: #17207a; 
  text-align: left; 
  text-justify: inter-word; 
}

.rootContainer {
  // min-height: 900px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .QuestionText {
    font-size: 30px;
  }
  // .sectionCard {
  //  padding-top: 10%;
  // }
}

// @media (max-width: 676px) {
//   .sectionCard {
//    padding-top: 40%;
//   }
// }

@media (min-width: 992px) {
  .Footer {
    height: 100px;
  }
}

.Onboarding {
  
}

/* named gradients used by screener section config */
// .blue {
//   background: linear-gradient(
//       180deg,
//       rgba(93, 159, 255, 0.175) 0%,
//       rgba(107, 187, 255, 0.7) 98.44%
//     ),
//     #ffffff;
// }
// .orange {
//   background: linear-gradient(
//       180deg,
//       rgba(221, 214, 243, 0.175) 0%,
//       rgba(250, 172, 168, 0.451171) 40.63%,
//       rgba(250, 172, 168, 0.7) 100%
//     ),
//     #ffffff;
// }
// .green {
//   background: linear-gradient(
//     180deg,
//     rgba(67, 233, 123, 0.1) 0%,
//     rgba(56, 249, 215, 0.4) 100%
//   ),
//   #ffffff;
// }
// .purple {
//   background: linear-gradient(
//       180deg,
//       rgba(253, 203, 241, 0.1) 0%,
//       rgba(253, 203, 241, 0.25) 31.25%,
//       #e6dee9 100%
//     ),
//     #ffffff;
// }
