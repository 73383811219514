

.press-byline {
  font-weight: bold;
  color: rgba(0,0,0,0.7);
}
.press-article a {
    color: rgb(25 78 179);
}
.press-article a:hover, .press-article a:focus {
    color: rgb(0 143 152);
}
.press-article p {
    line-height: 2.0;
    /* app default is 1.5 */
}