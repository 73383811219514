.icon {
    /* border: 2px solid #000033;
    padding: 5px; 
    border-radius: 50%; */
    font-size: 1.5rem !important;
    vertical-align: middle;
    margin-top: 2px;
    cursor: pointer;
    color:black; 
}

.icon-vertical-card {
    display:inline-block;
    font-size: 40px;
    vertical-align: bottom;
}