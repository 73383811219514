@import "../styles/colors.scss";

/* btn-primary should be our main brand color once we finalize palette */

.btn-primary { /* was just .btn */
  background-color: $dark-blue; 
  color: $white; 
  // border-radius: 8px; 
  border-color: $dark-blue; 
  // padding: .5em 1.25em;
}

.btn-primary:hover {
  background-color: $other-blue;
  border-color: $other-blue;
  outline: 0px; 
  color: $white; 
}

.btn-primary-2 { /* temp */
  background-color: $white; 
  color: $viridian-green; 
  border-radius: 8px; 
  border-color: $viridian-green;
}

.btn-primary-2:hover { /* temp */
  background-color: $viridian-green; 
  color: $white; 
  border-radius: 8px; 
  border-color: $viridian-green;
}

.btn-primary-outline {
  background-color: $white; 
  color: $viridian-green; 
  // padding: 4px 12px 4px; 
  border: solid 1px;
  // border-radius: 8px; 
  border-color: $viridian-green;
}

.btn-primary-outline:hover {
  background-color: $viridian-green !important; 
  color: $white !important; 
  border-radius: 8px; 
  border-color: $viridian-green; 
  cursor: pointer;
}

.btn-outline {
  background-color: $white; 
  color: $dark-blue; 
  // padding: 4px 12px 4px; 
  border: solid 1px;
  // border-radius: 8px; 
  border-color: $dark-blue
}

.btn-outline:hover {
  background-color: $dark-blue !important; 
  color: $white !important; 
  border-radius: 8px; 
  border-color: $dark-blue; 
  cursor: pointer;
}

.btn-pink {
  background-color: #E14798;
  color: white;
  border-radius: 8px;
  padding: .5em 1.25em;
  &:hover {
    color: $viridian-green !important; 
    background: $btn-highlight;
  }
}

.btn-pink-outline {
  background-color: white;
  color: #E14798;
  border-radius: 8px;
  padding: .5em 1.25em;
  &:hover {
    color: $viridian-green !important; 
    background: $btn-highlight;
  }
}
  
  // CTA Buttons 
.cta-primary {
  color: $white; 
  background: $magenta-pink;
  border: 1px solid $white;
  border-radius: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
  font-size: 14px; 
  padding: 15px 30px; 
  letter-spacing: 1px;
}

.cta-primary:hover {
  color: $viridian-green !important; 
  background: $btn-highlight;
  border: 1px solid #2F353A;
}

.cta-primary img {
  height: 14px; 
  vertical-align: baseline; 
}

.cta-primary:hover > img {
  filter: invert(55%) sepia(10%) saturate(2564%) hue-rotate(134deg) brightness(90%) contrast(82%);
  // filter: opacity(0.5) drop-shadow(0 0 0 #000000);
}

.cta-primary-outline {
  color: $magenta-pink; 
  background-color: $white; 
  border: solid 1px $magenta-pink;
  border-radius: 0px; 
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
  font-size: 14px; 
  padding: 15px 30px; 
  letter-spacing: 1px;
}

.cta-primary-outline:hover {
  color: $viridian-green !important; 
  background: $btn-highlight;
  border: 1px solid #2F353A;
}

.cta-primary-outline:hover > img {
  filter: invert(55%) sepia(10%) saturate(2564%) hue-rotate(134deg) brightness(90%) contrast(82%);
}

// Onboarding Buttons
.onboarding-btn {
  background: $dark-blue;
  border: none;
  min-width: 5rem;
  font-weight: bold;
  letter-spacing: .1rem;
}

.onboarding-btn:hover {
  background: $dark-blue;
  border: $dark-blue;
}

// Dropdown Buttons
.dropdown-btn {
  background: $dark-blue;
  border: $dark-blue;
}

// Navigational Buttons
.back-btn, .back-btn:hover {
  background: $white;
  border: 2px solid $dark-blue; 
  color: $dark-blue; 
}
.forward-btn, .forward-btn:hover {
  background: $dark-blue !important; 
  border: 2px solid $dark-blue;
}

// Yes / No / Unsure Buttons in Questionnaire 
.GreenButton {
  background: #549c64;
  outline: 0;
  box-shadow: none!important;
}

.GreenButtonActive,
.GreenButtonActive:hover, 
.GreenButtonActive:focus {
  background: #0d7726;
  border-color: #12af36;
  box-shadow: 0 0 0 0.5rem rgba(18, 175, 54, 0.25);
}

.BlueButton {
  background: #3998fd;
  outline: 0;
  box-shadow: none!important;
}

.BlueButtonActive,
.BlueButtonActive:hover, 
.BlueButtonActive:focus {
  background: #0352a7;
  border-color: #007bff;
  box-shadow: 0 0 0 0.5rem rgba(2, 114, 234, 0.25);
}

.RedButton {
  background: #d84a58;
  outline: 0;
  box-shadow: none!important;
}

.RedButtonActive,
.RedButtonActive:hover, 
.RedButtonActive:focus {
  background: #971421;
  border-color: #da1d2f;
  box-shadow: 0 0 0 0.5rem rgba(216, 74, 88, 0.25);
}

// Other Colored Buttons 
.btn-viridian-green {
  background: $viridian-green;
  border-color: $viridian-green;
}

.btn-viridian-green:hover,
.btn-viridian-green:focus {
  background: $white;
  border: 2px solid $viridian-green;
  color: $viridian-green;
}

.btn-green {
  background: $green;
  border-color: $green;
}

.btn-dark-green,
.btn-dark-green:hover,
.btn-dark-green:focus {
  background: $dark-green;
  border-color: $dark-green;
  color: $white;
}