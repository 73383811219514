// @import '../../../configs/styles/custom';

.card-header a:link {
  color: inherit;
  text-decoration: none;
}

.card-header a:focus, a:hover {
  // color: $grey;
  text-decoration: none;
}

// @include media-breakpoint-down(md) {
//   .card-body {
//     padding-left: $spacing-20;
//     padding-right: $spacing-20;
//   }
// }
