@import "../styles/colors.scss";
@import "../styles/typography.scss";
@import "../styles/helpers.scss";
@import "../styles/video.scss";
@import "../styles/carousel.scss";
@import "../styles/button.scss";
@import "../styles/dropdown.scss"; 

// Universal 
html, body {
  height: 100%;
  margin: 0; 
  color: $charcoal; 
}

#root {
  position: relative;
}

input[type="checkbox"] {
  transform: scale(1.2) !important;
}

.hide {
  display: none;
}

// For Android when keyboard is pushed up
@media (max-height: 480px) {
  .History_Footer__1OwKk {
    position: relative;
    z-index: -1;
  }
}

// Background Images 
.bg-pattern {
  background-image: url(../../public/images/pattern-bg.png); 
}

// Background Colors
.bg-white {
  background: #FFFFFF; 
}

.bg-green {
  background: $green !important;
}

.bg-dark-green {
  background: $dark-green !important;
}

.bg-dark-cyan {
  background: $dark-cyan !important;
}

.bg-light-green {
  background: $light-green !important;
}

.bg-light-grey {
  background: $light_grey !important;
}

.bg-blue {
  background: $blue !important;
}

.bg-dark-blue {
  background: $dark-blue !important;
}

.bg-brown {
  background: $brown !important;
}

.bg-purple {
  background: $purple !important;
}

.bg-light-purple {
  background: $light-purple !important;
}

.bg-red {
  background: $red !important;
}

.bg-light-blue {
  background: $light_blue !important;
}

.bg-light-grey {
  background: $light_grey !important;
}

.bg-light-blue-gradient {
  background: linear-gradient(0deg, #ffffff 0%, #b2ebed 100%);
}

.bg-viridian-green {
  background: $viridian-green;
}

.bg-viridian-green-gradient {
  background: linear-gradient(45deg, #d5f5f6 0%, #b2ebed 100%);
}

.bg-persian-green {
  background: $persian-green;
}

.bg-light-red-orange {
  background: $light-red-orange;
}

.bg-light-orange {
  background: $light-orange;
}

.bg-light-cyan-blue {
  background: $light-cyan-blue; 
}

// Outline 
.bg-outline {
  border: 4px solid $viridian-green;
  border-radius: 0.25rem;
}

// Borders 
.border-green {
  border-color: $green !important;
}

.border-blue {
  border-color: $blue !important;
}

.border-brown {
  border-color: $brown !important;
}

.border-purple {
  border-color: $purple !important;
}

.border-red {
  border-color: $red !important;
}

// Images
.full-width-image {
  width: 100%;
  position: relative;
}

.full-width-image img {
  width: 100%;
}

.cartoon-img {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.centered-img {
  width: 120px; 
  float: right;
}

@media only screen and (max-width: 480px){
  .centered-img {
    display: flex; 
    justify-content: center;
    margin-top: 30px;
  }
}

.partner-logo {
  // height: 65px;
  margin-bottom: 1.5rem;
  filter: grayscale(100%);
}

.partner-logo {
  @media (max-width: 991.98px) {
    margin-bottom: 3rem;
  }
}

// Icons

.icon-top {
  height: 90px; 
  padding-bottom: 12px;
}

// Text Containers

.content-box {
  padding: 45px;
  text-align: center;
  box-shadow: 0px 0px 20px #EAE5EE;
  border-radius: 4px;
  font-size: 20px;
  line-height: 45px;
  @media (max-width: 991.98px) {
    margin: 15px 0;
    padding: 35px;
  }
}

.content-box-outer {
  margin: 30px 0px;
}

.content-box-middle {
  margin: 0;
  @media (max-width: 991.98px) {
    margin: 15px 0;
    padding: 35px;
  }
}

.content-box-middle-text {
  @media (max-width: 991.98px) {
    margin: 30px 0;
  }
}

.content-box img {
  height: 100px; 
  margin-top: 0;
}

.top-border {
  line-height: 45px;
  border-top-style: solid; 
  border-top-color: $viridian-green;  
  border-width: .5em; 
  border-top-left-radius: .75em; 
  border-top-right-radius: .75em; 
}

.bottom-border {
  line-height: 45px;
  margin-bottom: 75px;
  border-bottom-style: solid; 
  border-bottom-color: $viridian-green;  
  border-width: .5em; 
  border-bottom-left-radius: .75em; 
  border-bottom-right-radius: .75em; 
}

/* Public Card Only - Patient questionnaire cards handled in its own file */
.public-page .card {
  box-shadow: 0px 2px 15px rgba(84, 110, 122, 0.25);
  border: none;
  // padding: 1rem;
  padding: 0px;
  height: auto;
  font-size: 20px;
  font-weight: normal; 
}
.card.card-horizontal {
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  font-size: 20px;
  font-weight: normal; 

  img {
    border-radius: 0.25rem;
    height: 100%;
  }
}

.badge-v-center {
  position: relative;
  bottom: 0.125rem;
}


.spacer1 {
  margin: 4.5rem 0;
}
.spacer2 {
  margin: 6rem 0;
}

.link-underline {
  border-bottom: 2px solid;
}
.card-wrapper {
  max-width: 85%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

// Move to a more suitable file? 
// News Tab Panes used in Press Page 
.nav-pills {
  font-size: 1.15rem;
  .nav-link.active,
  .nav-link:hover {
    font-weight: bold;
    color: $viridian-green;
    background-color: inherit;
    border-bottom: 4px solid $viridian-green;
    border-radius: 0;
  }
}

.otherNews__item {
  border-bottom: 1px solid #c4c4c4;
  padding: 1rem;

  &:hover {
    background: rgba(51, 148, 153, 0.25);
  }

  a:hover {
    color: $viridian-green;
  }
}

.table-print-denser {
  margin-bottom: auto;
}
.print-only-block {
  display: none;
}
@media only print {
  .table-print-denser {
    width: auto;
  }
  .table-print-denser td {
    padding: 0.3rem;
    padding-left: 1rem;
  }
  .print-half {
    width: 50%;
  }
  .no-print {
    display: none;
  }
  .print-only-block {
    display: block;
  }
  .content-wrapper {
    margin-top: 0;
  }
  h2 {
    padding: 0;
  }
  .provider-bar {
    display: none;
  }
  .print-no-borders {
    border: transparent !important;
    box-shadow: none !important;
  }
}

// Move these to more suitable files? 
.DEBUG {
  background-image: linear-gradient(45deg, #e6b447 25%, #ff9f45 25%, #ff9f45 50%, #e6b447 50%, #e6b447 75%, #ff9f45 75%, #ff9f45 100%) !important;
  background-size: 45.25px 45.25px !important;
}

tr.highlightable td {
  transition: background-color 1s;
}
tr.highlight-row td {
  background-color: #d0f0f1;
  transition: background-color 1s;
}

.sortable {
  text-decoration: dotted underline;
  text-underline-offset: 5px;
}
.sortable.current-sort {
  // color:rgb(0, 90, 129);
  text-decoration: double underline;
}

.table tbody tr.stripe-even {
  // background-color: #D5F5F6;
  background-color: #F0F0F0;
  &.invite-header {
    background-color: #d4ebeb !important;
  }
}
.table tr.stripe-odd.invite-header {
  background-color: #ecf7f7 ;
}

.table tr.stripe-even td, .table tr.stripe-odd td {
  border-top: none;
}
.table tr.stripe-even.group-start td, .table tr.stripe-odd.group-start td {
  border-top: 1px;
  padding-bottom: 0;
}
.table tr.status-row td {
  border-left: none !important;
}
.table tr.stripe-even:not(.group-start) td:first-child, .table tr.stripe-odd:not(.group-start) td:first-child {
  border-left: solid 12px white;
  // border-left: solid 3px rgba(51, 148, 153, 0.25)
}
tr.dotted-top td {
  border-top: 1px dotted gray !important;
}

.clinician-table td, #history td {
  vertical-align: middle;
}

textarea.empty:not(:focus) {
  opacity: 75%;
}

.badge-lg {
  line-height: inherit;
  font-size: inherit;
}
.hover-blue:hover {
  color:#17527a !important;
  cursor: pointer;
}

a.keyword-highlight {
  font-weight: bold;
  background-color: #addde5;
  padding-right: 4px;
  margin-right: -4px;
  padding-left: 4px;
  margin-left: -4px;
  cursor: pointer;
}

textarea.form-control {
  line-height: 32px;
}

.info-button {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.5em;
  color: white;
  text-shadow: 0px 0px 5px #000000;
}

.text-underline {
  text-decoration: underline !important;
}

.btn-xs {
  padding: 0px 6px 0px !important;
  font-size: .75rem;
  line-height: 1.25;
}

.basic-shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 10;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  font-size: 80%;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}
.MuiDataGrid-root .MuiDataGrid-columnHeader {
  padding: 0 5px !important;
}
.mui-to-bs .MuiInputBase-root {
  padding: 5px 5px 5px 16px;
  border: solid 1px rgb(206, 212, 218);
  border-radius: 4px;
}
.mui-to-bs .MuiInput-underline:before {
  border-bottom: none;
}

// move to explore page
.selected-node {
  color: green;
  filter: drop-shadow(1px 1px 4px yellow);
}
g.hidden-node, path.hidden-link {
  opacity: 10%;
}
g.fixed-node circle {
  filter: drop-shadow(0px 0px 2px black);
}

.carousel-indicators {
  bottom: -2.75rem !important;
}