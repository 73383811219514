/* .education {
  display: flex; 
  flex-direction: column;
  justify-content: center;
}

.education a {
    color: rgb(25 78 179);
}
.education a:hover, .education a:focus {
    color: rgb(0 143 152);
} */

.education body {
  background-color: #f0f0f2;
  margin: 0;
  padding: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.popup-education-wrapper {
  max-width: 600px;
  margin: auto;
  padding: 2em;
  /* background-color: #ffffff; */
  border-radius: 0.5em;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
}

.medical-info-wrapper {
  background-color: rgb(255, 255, 198);
  padding: 12px;
  padding-left: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.education h1 {
  font-size: 1.5rem; 
}

.education h2 {
  padding-bottom: 0 !important; 
  margin-bottom: 10px;
}

/* .education p {
  line-height: 2.0;
} */

.education img {
  /* display: block; */
  width: 250px; 
  margin: 0 auto; 
}

.education ul {
  list-style-type: circle;
}

.education a {
  color: cadetblue; 
}

.education a:link, .education a:visited {
  color: #38488f;
  text-decoration: none;
}

@media (max-width: 700px) {
  .education div {
      margin: 0 auto;
      width: auto;
  }
}