.PatientImg {
  display: table-cell;
  background-image: url("../assets/patient.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.PatientAtClinicImg {
  display: table-cell;
  background-image: url("../assets/patient_at_clinic.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.DoctorImg {
  display: table-cell;
  background-image: url("../assets/doctor.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.WomanGettingVaccineImg {
  background-image: url("../assets/woman_getting_vaccine.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 300px;
}

.DoctorWithPatientImg {
  background-image: url("../assets/doctor_with_patient.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 300px;
}

@media only screen and (max-width: 991px) {
  .PatientImg,
  .DoctorImg,
  .PatientAtClinicImg {
    height: 40vh;
  }
}
