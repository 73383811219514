.badge-primary {
  background-color: #77DD77;
}
.badge-secondary {
  background-color: #B7C00C;
}
.badge-success {
  background-color: #3EB489;
}
.badge-danger {
  background-color: #E43D40;
}