.main-viewer {
    margin: 0 0 0 200px; 
    padding: 3%; 
}

ul {
    padding-left: 16px;  
}

.status-btn {
    background-color: #29a19c;
    border: none;
    margin-left: 8px;
    padding-top: 12px; 
}

@media only screen and (max-width: 600px) {
    .main-viewer {
        margin: 0 auto; 
    }
    .status-btn {
        margin-top: 8px;
        padding-top: 6px; 
    }
}