@import "../styles/colors.scss";

#hero {
  margin: 30px 0; 
  // margin-bottom: 0;
  background-color: $white; 
}

.left-padding {
  padding-left: 4rem;
}

#solution {
  text-align: center; 
  padding: 45px; 
  margin: 0 auto; 
}

#steps {
  padding: 45px 0;
}

#steps .btn {
  margin: 20px 0 30px 0;
}

#steps .btn img {
  height: 12px; 
}

#partners {
  padding: 45px 0;
}

#partners img {
  height: 75px; 
}

.ColContainer {
  padding: 40px;
}

.TextContainer {
  padding: 0 40px 40px 40px;
}

@media (min-width: 450px) and (max-width: 575px) {
  .ColContainer {
    padding: 60px;
  }

  .TextContainer {
    padding: 0 60px 60px 60px;
  }
}

@media (min-width: 576px) {
  .ColContainer {
    padding: 20px;
  }

  .TextContainer {
    padding: 0 20px 20px 0px;
  }
}

.landing-alert {
  /* position: absolute;
  top: 125px; */
  width: 100%;
  text-align: center;
  z-index: 20;
  @media (min-width: 992px) {
    margin-right: 80px;
  }
}
.landing-alert .alert {
  display: block;
  max-width: 100%;
  margin-bottom: 0;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
  @media (max-width: 991.98px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.navbar-nav {
  margin-top: 0;
  @media (max-width: 991.98px) {
    &:first-child {
      margin-top: 1rem;
    }
    border-top: 1px solid #8ebabd;
  }
}
.nav-link {
  @media (max-width: 991.98px) {
    padding-left: 0.5rem !important;
  }
  @media (min-width: 992px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  &:hover {
    background: rgba(255,255,255,0.1);
    text-shadow: 0 0 10px #016269;
  }
}

.no-margin-md {
  @media (min-width: 992px) {
    margin: 0 !important;
  }
}
