.boxedForm {
 display: block; 
 border: 1px solid #CCCCCC; 
 margin-top: 35px;
 padding: 35px 25px 25px 25px; 
 background-color: #FFFFFF; 
 box-sizing: border-box; 
 box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
 border-radius: 4px;
}

.label-wrapper {
  text-align: center;
  height: 12px; 
}

.sectionLabel {
  position: relative; 
  top:-60px; 
  padding:8px 15px; 
  display: inline-block; 
  background: #FFFFFF;  
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15); 
  border-radius: 4px; 
}

.tableLabel {
  padding:8px 15px; 
  color: #FFFFFF; 
  display: block; 
  background: #339499;  
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05); 
  border-radius: 4px; 
}

.tableLabel h4 {
  margin: 6px; 
}