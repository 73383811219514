.react-datepicker-wrapper {
  display: inline-block;
  // width: inherit;
}

group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex: 1 1 auto;
}


.react-datepicker__current-month, .react-datepicker__current-month--hasYearDropdown,
.react-datepicker__current-month--hasMonthDropdown,
.react-datepicker__navigation, .react-datepicker__navigation--next {
  display: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
