/* Public Card is handled in "Common" file. */
.card {
  height: 100%;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.card-img-top {
  width: 100%;
  position: relative;
}
img.card-img-top, .card-img-top > img {
  width: 100%;
}
.card-body {
  flex: inherit;
}

.question-card .card-text {
  font-size: 90%;
}

.pad-for-footer {
  padding-bottom: 80px;
}
@media (min-width:768px){
  .pad-for-footer {
    padding-bottom: 105px;
  }
}

/*For the "none of these" option*/
.card .svg {
  height: 50% !important;
  width: 50%;
}
/* .card {
    width: 180px;
    text-align: center;
    margin: 0;
    margin: 0 0 25px 16px;
    border: 1px solid black;
    border-radius: 8px;
} */

.selected {
  border: 3px solid #17207a;
  background: #F4F4FA;
}
.suggested {
  border: 3px solid aquamarine;
  background: #F4F4FA;
}

/* 
  NOTE: The ordering of the card becoming :active and firing its event handlers
  (and thus marking it .selected) is tricky on mobile and depends on the length
  of the touch -- presumably the browser is waiting to see if the touch becomes
  a "long press". As a result, you can't really consistently time the order of
  operations with CSS and basic events alone. Instead we only target using this
  on desktop and ONLY on the activate case. This is a temporary solution until
  we start using React/JS-based animation helpers...
*/
.question-card:not(.selected):active {
  transform: scale(.98);
}
/* .question-card:active {
  transform: scale(.98);
} */

/* .card > img {

    width: 180px;
    border-radius: 10px 10px 0 0;
}
.card > p {
    padding: 8px;
}
.card:hover {
    -webkit-box-shadow: -1px 9px 40px -12px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 9px 40px -12px rgba(0,0,0,0.75);
    box-shadow: -1px 9px 40px -12px rgba(0,0,0,0.75);
}

@media only screen and (max-width: 600px) {
    .card {
        width: 165px;
    }
    .card > img {
        width: 165px;
    }
} */

.included-check {
  position: absolute;
  right: -16px;
  top: -20px;
  font-size: 2rem;
  background: white;
  border-radius: 16px;
  color: #12af36;
  line-height: 36px;
  z-index: 10;
}
.suggested .included-check {
  color: aquamarine;
}
