/* PATIENT HISTORY REPORT */
.tab-wrapper {
  max-width: 1024px;
  margin: 0 auto;
}
.report {
  margin: 0 auto;
  z-index: 1;
  max-width: 1024px;
  min-height: 540px;
  overflow: auto;
  position: relative;
}
.patient-history {
  font-family: "Ubuntu";
  color: black;
  font-size: 40px;
  text-align: center;
}
.options-wrapper {
  display: flex;
  justify-content: space-between;
}
.go-back-options {
  padding: 5px;
}
.view-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 5px;
}
/* #view-as-text {
  margin-right: 1em;
}
.plain-English {
  display: inline;
}
.doctor-speak {
  display: none;
} */
.content-wrapper {
  margin-top: 3%;
}
.action-items-wrapper {
  display: inline-block;
  padding: 5px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 600px) {

  .tab-wrapper {
    width: 295px;
  }
}

.report ul {
    margin-bottom: 0;
}

.report span.badge {
  vertical-align: middle;
  margin-bottom: 2px;
}

.report .question-label em:hover {
  border-bottom: 1px gray dotted;
}