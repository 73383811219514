#footer-bottom {
    position: absolute;
    color: #fefefe;
    bottom: 0;
    text-align: center;
    width: 100%;
}
#footer-sm {
    background-color: #339499;
    color: #fefefe;
    max-height: 450px;
    padding: 45px 15px 45px 15px;
}
#footer-lg {
    background-color: #339499;
    color: #fefefe;
    padding: 45px 15px 0px 15px;
}

#footer .nav-link {
    display: inline-block;
    color: #cedede;
    padding: 0.25rem 0.5rem;
    text-align: left;
}

@media only screen and (max-width: 480px) {
    #footer { 
        text-align: center; 
    }
  }