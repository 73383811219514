// *********************** Brand Colors *********************** //

// Primary Color Palette 
$viridian-green: #339499; 
$dark-cyan: #016369; 
$magenta-pink: #E14798; 
$light-magenta-pink: #e55da4;
$light-red-orange: #FAD6C5; 
$charcoal: #394149; 

// Highlight & Accent Colors 
$btn-highlight: #94EBDB;
$light-orange: #FFEBE1; 
$light-cyan-blue: #ECF2F9; 


// *********************** General Colors *********************** //
// Reds
$red: #e91e63; 

// Yellows
$yellow: #F9E559; 

// Blues
$light_blue: #e2f4f4;
$blue: #4eafc5; 
$dark-blue: #17207a;
// $dark-blue: #1a1053;
$other-blue: #17527a; 

// Orange

// Greens
$light-green: #e6fff6; 
$green: #1ab984; 
$dark-green:#027950; 
$persian-green: #008f98; 

// Purples
$purple: #7B4E8B; 
$light-purple: #A47DB2; 

// Tertiary Colors - Green / Blues 
$light-cyan: #d5f5f6; 
$dark-turquoise: #54cfd6;
$riptide: #94EBDB; // currently, btn-highlight for main CTA 

// Neutrals 
$white: #ffffff;
$off-white: #f9fafb;
$light_grey: #f0f0f0; 
$grey: #72777a;
$dark-grey: #313435;
$brown: #795548;
$black: #000000;