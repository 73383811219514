@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400&display=swap");


// Fonts 
body {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.2px;
  margin: 0 auto;
  font-size: 1rem;
}

h1 {
  font-weight: 400;
  letter-spacing: 0px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  font-size: 40px;
}

h2 {
  font-weight: 400;
  letter-spacing: 0px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  font-size: 30px;
  padding-bottom: 24px;
}

h3 {
  font-weight: 700;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
}

h4 {
  font-weight: 700;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

h5 {
  font-weight: 700;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  font-size: 28px;
}

p {
  font-size: 16px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}

// Extra large
@media (min-width: 1200px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 32px;
  }
}

// Typography
.heading1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  // font-size: 2.25rem;
  text-transform: capitalize;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding-bottom: 0;
  margin-bottom: 1.5rem;
}

.heading2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.heading3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  margin-bottom: 1.5rem;
}

.subtitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  margin: 25px 0px 0px 0px; 
  padding-bottom: 0px;
}

.paragraph {
  font-size: 18px;
  color: $charcoal;
  margin-bottom: 1.5rem;
}

.magnify-text {
  display: inline-block; 
  font-size: 45px;
}

.magnify-text-middle {
  margin: 12px 0; 
}

// Text Colors 
.text-white {
  color: $white !important;
}

.text-green {
  color: $green !important;
}

.text-viridian-green {
  color: $viridian-green !important;
}

.text-light-green {
  color: $light-green !important;
}

.text-blue {
  color: $blue !important;
}

.text-dark-turquoise {
  color: $dark-turquoise !important;
}

.text-dark-blue {
  color: $dark-blue !important;
}

.text-brown {
  color: $brown !important;
}

.text-purple {
  color: $purple !important;
}

.text-red {
  color: $red !important;
}

.text-grey {
  color: $grey !important;
}

.text-dark-grey {
  color: $dark-grey !important;
}

.text-charcoal {
  color: $charcoal !important;
}

.text-light-blue {
  color: $light_blue !important;
}

.text-light-grey {
  color: $light_grey !important;
}

.text-yellow {
  color: $yellow !important;
}

.text-magenta-pink {
  color: $magenta-pink; 
}

// Text Rendering

.text-wrapper {
  max-width: 440px;
}

// Script Direction
*[dir="ltr"] {
  direction: ltr;
  unicode-bidi: embed;
}

*[dir="rtl"] {
  direction: rtl;
  unicode-bidi: embed;
  unicode-bidi: bidi-override !important;
  text-align: right;
}

// Links 
a {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: inherit;
}

a:focus,
a:hover {
  text-decoration: none;
  cursor: pointer;
}

.link-underline {
  border-bottom: 2px solid;
}

// Lists 
ul {
  list-style: none;
}

// ul li:before {
//   content: '✓' !important;
//   color: $white;
//   font-family: 'ETMODULES'!important;
//   margin-right: 10px;
//   margin-left: -14px;
//   font-size: 1.2em!important;
//   }

.list {
  list-style: inherit;
  margin-bottom: 1.5rem;
  padding-left: 2rem;
  font-size: 18px;
}

.list li {
  margin-bottom: 0.75rem;
  padding-bottom: 8px;
}