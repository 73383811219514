.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin__heading {
  font-size: 1.25rem;
  font-weight: 700;
}
.top {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.middle {
  border-radius: 0;
  margin-bottom: -1px;
}
.bottom {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* Styles to fix DOB field in anonymous sign in form. This field has a lot of bugs and needs refactoring */
.form-signin__anonymous .MuiTextField-root {
  width: calc(100% - 42px);
}
.form-signin__anonymous .MuiInput-root.MuiInput-underline::before {
  border-bottom: none;
}

.form-signin__anonymous .MuiInput-root {
  padding-left: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: calc(1.5em + 0.75rem + 2px);
}

/* - */
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
