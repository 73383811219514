.in-text-select {
  border: 1px rgba(0, 0, 0, 0.4) dotted;
  border-radius: 2px;
  background-color: rgba(256, 256, 256, 0.8);
  color: gray;
  width: unset;
  padding: 2px 6px;
}


.clinician-home {
  min-height: calc(100vh - 62px);
}

.clinician-main {
  background-color: #a1d4e128;
  .MuiDataGrid-root {
    background: white;
  }
  height: calc(100vh - 62px);
  overflow-y: scroll;
}

.time-nav .row {
  height: 38px;
  // .pagination li {
  //   height: 38px;
  // }
  .page-link {
    color: #339499;
    &.active {
      color: white;
      background-color: #339499;
    }
  }
}

.clinician-sidebar {
  background: rgb(255, 255, 255);
  border-right: 1px solid #CCCCCC;
  padding-right: 0px;
  height: 100%;
  h4.sidebar-title {
    color: #339499;
    &:not(:first-of-type) {
      // border-top: 1px solid #AAAAAA;
      padding-top: 12px;
    }
  }
  .page-links {
    height: calc(100vh - 62px);
    .entry, .sidebar-title, .sidebar-subtitle, .none-message {
      display: block;
      padding: 0.25rem 1rem;
      margin: 0.25rem 0;
    }
    .sidebar-subtitle {
      padding-left: 1.5rem;
    }
    .entry:not(.selected):hover {
      background-color: rgba(0, 0, 0, 0.05);
      border-right-color: #CCCCCC;
    }
    .none-message {
      font-style: italic;
      font-size: 80%;
    }
    .entry {
      cursor: pointer;
      border-right: 4px solid transparent;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-left: 2rem;
      &.sublevel {
        padding-left: 2.75rem;
        font-style: italic;
      }
    }
    .selected {
      // background: #D5F5F6;
      background: #7A49A533;
      border: none !important;
    }
    // overflow-y: scroll;
    padding-top: 8px !important;
  }
  // .organization {
  //   height: calc(33vh - 20px);
  //   border-bottom: 1px solid #CCCCCC;
  //   background: #F9F9F9;
  //   h4 {
  //     color: #7A49A5;
  //   }
  //   .sidebar-subtitle {
  //     margin-top: -4px;
  //     font-style: italic;
  //   }
  //   .entry:not(.selected):hover {
  //     background-color: rgba(0, 0, 0, 0.05);
  //     border-right-color: #CCCCCC;
  //   }
  // }
  // .records {
  //   min-height: calc(67vh - 20px);
  //   .entry:not(.selected):hover {
  //     background-color: #D5F5F6;
  //     border-right-color: #339499;
  //   }
  // }
  // .new-reports {
  //   height: calc(40vh - 20px);
  // }
  // .recently-viewed {
  //   height: calc(26vh - 20px);
  //   margin-top: 8px;
  //   border-top: 1px #CCCCCC solid;
  //   padding-top: 8px;
  // }
  overflow-y: scroll;
}

.patient-summary {
  width: 100%;
  margin-top: 0em;
  margin-bottom: 2em;
  padding: 4px;
  th {
    border-left: #339499 4px solid;
    padding-left: 12px;
  }
  .patient-name {
    font-weight: normal;
    text-transform: uppercase;
  }
  .patient-age {
    color: #339499;
  }
  label {
    color: #6c757d; // same as text-muted
    padding-right: 1em;
  }
}

.MuiDataGrid-root.reports-grid {
  border-radius: 12px;
  .MuiDataGrid-columnHeaderWrapper {
    padding: 8px; 
  }
  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
    // line-height: 40px;
  }
  .MuiDataGrid-columnsContainer {
    background: #D5F5F6;
  }
  .MuiDataGrid-menuIcon {
    margin-right: 0px;
    position: relative;
    right: 6px;
  }
  .MuiDataGrid-columnSeparator {
    color: #339499;
    pointer-events: none;
    // opacity: 0;
    display: none; 
  }
  .reports-header {
    background-color: #339499;
    color: white;
    padding: 8px;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    h4 {
      margin-bottom: 0;
      padding-left: 6px; 
    }
  }
}

.MuiDataGrid-root {
  border: none !important; 
  border-radius: 0%;
}

.MuiDataGrid-root .unread .MuiDataGrid-cell {
  font-weight: bold;
}

.patient-details-page .history-timeline {
  border-radius: 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  .history-title {
    background: #339499;
    color: white;
    padding: 8px;
    margin: -6px -16px 5px -16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.provider-bar {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #339499;
  color: white;
} 

.table-tabs .nav-link:not(.active) {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-color: #A1A1A1;
  color: white; 
}
.table-tabs .nav-item+.nav-item .nav-link {
  margin-left: 4px;
}
.table-tabs .inbox-group {
  display: flex;
  position: relative; /* defines a new rendering  */
  align-items: flex-end;
  height: calc(3rem + 24px + 2px);
  .inbox-group-label {
    position: absolute;
    display: block;
    top: 0px;
    width: 100%;
    text-align: center;
    line-height: 1.4rem;
    font-style: italic;
  }
  .nav-link {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
}

.keep-layout-med .navbar-nav {
  flex-direction: row;
  border-top: none;
}