// Carousel
.carousel-indicators {
  bottom: -4.5rem;

  li {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #c4c4c4;
  }
}
.carousel-control-next,
.carousel-control-prev {
  opacity: 0.9;
  width: 5%;

  i {
    font-size: 1rem;
    padding: 0.75rem;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
    @media (min-width: 1440px) {
      font-size: 3rem;
    }

    &:hover {
      background-color: #339499;
    }
  }
}