/* Was duplicated 3x */
/* .btn-primary { 
  background-color: #17207a;
  color: #FFFFFF;
  border-radius: 8px;
  border-color: #17207a
} */

.btn-primary-2 { /* temp */
  background-color: #FFFFFF;
  color: #339499;
  border-radius: 8px;
  border-color: #339499;
}

.btn-primary-2:hover { /* temp */
  background-color: #339499;
  color: #FFFFFF;
  border-radius: 8px;
  border-color: #339499;
}

.btn-primary:hover {
  background-color: #17527a;
  border-color: #17527a;
  outline: 0px;
  color: #FFFFFF;
}

/* btn-primary should be our main brand color once we finalize palette */
.btn-primary-outline {
  background-color: #FFFFFF;
  color: #339499;
  padding: 4px 12px 4px;
  border: solid 1px;
  border-radius: 8px;
  border-color: #339499;
}

.btn-primary-outline:hover {
  background-color: #339499 !important;
  color: #FFFFFF !important;
  border-radius: 8px;
  border-color: #339499;
  cursor: pointer;
}

.btn-outline {
  background-color: #FFFFFF;
  color: #17207a;
  padding: 4px 12px 4px;
  border: solid 1px;
  border-radius: 8px;
  border-color: #17207a
}

.btn-outline:hover {
  background-color: #17207a !important;
  color: #FFFFFF !important;
  border-radius: 8px;
  border-color: #17207a;
  cursor: pointer;
}

.onboarding-btn {
  background: #17207a;
  /* marginTop: "0.75em", */
  border: none;
  min-width: 5rem;
  font-weight: bold;
  letter-spacing: .1rem;
}

.onboarding-btn:hover {
  background: #17207a;
  border: #17207a;
}

.dropdown-btn {
  background: #17207a;
  border: #17207a;
  /* #1a9fb9 */
}

.button-inline {
  margin-left: 1rem !important;
}

/* These are defined twice, need to remove duplicate */
/* .back-btn, .back-btn:hover {
  background: white;
  border: 2px solid #17207a;
  color: #17207a;
}
.forward-btn, .forward-btn:hover {
  background: #17207a !important;
  border: 2px solid #17207a;
} */

/* .included-check {
  position: absolute;
  right: -16px;
  top: -20px;
  font-size: 2rem;
  background: white;
  border-radius: 16px;
  color: #12af36;
  line-height: 36px;
} */

.card-icon-tray {
  position: absolute;
  top: 0.25em;
  right: 0;
  font-size: 1.8em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0.25rem;
}

@media only screen and (max-width: 480px) {
  .button-inline {
      margin-left: 0 !important;
  }

  .btn-bottom {
    margin-top: 12px;
  }
}