.banner { 
  padding: 30px;
}

.banner-sm {
  padding: 0px; 
}

.banner-signup {
  text-align: right;
}

@media screen and (max-width: 480px) {
  .banner-signup {
    text-align: center;
  }

  h3 {
    padding: 0 !important; 
  }
}
/* .banner-dark {
  background: #2a505a; 
  color: #FFFFFF; 
} */

.title-banner {
  padding: 35px 0;
  font-size: 40px;
}