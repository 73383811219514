@import '../../../styles/common';

.Error {
	border-color: $red;
}

.ErrorMessage {
	color: $red;
	font-style: italic;
	margin-bottom: 1em;
}


/* Styling the flash messages*/
.CustomAlert {
  border-radius: 0;
  width: 100%;
  text-align: center;
}
