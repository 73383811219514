@import '../styles/common.scss';

.BrandText {
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 42px;
}

.NavText {
  font-size: 18px;
  line-height: 36px;
}

.NavCTA {
  // background: $dark-turquoise;
  // color: $white;
  // border-color: $dark-turquoise;
  // font-weight: bold;
  // border-radius: 10px;
  // font-size: 16px;
  // line-height: 0;
}

// Navbar
.nav-item {
  white-space: nowrap;
}

.Callout {
  position: absolute;
  right: 0;
  &.Upper {
    top: -28px;
  }
  &.Lower {
    top: 25px;
  }
  a {
    width: 220px;
    line-height: 10px;
  }
  // @media (max-width: 767.98px) {
  @media (max-width: 991.98px) {
    position: static;
    margin-top: 0.75rem;
    a {
      width: 100%;
    }
  }
}

.BrandLogo {
  margin-left: 1rem;
  @media (max-width: 991.98px) {
    margin-left: 0;
    margin-top: -5px;
  }
}

.ShowLogo {
  display: none;
  @media (min-width: 450px) {
    display: block;
  }
}